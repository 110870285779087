<template>
  <div class="fresh_scroll">
    <div
      v-for="(item,index) in activityList"
      :key="index"
    >
      <img
        v-if="item.typeInfo.temType == 1"
        class="tit"
        :src="item.goodsInfo[0].image"
        @click="gotoActhds(item.goodsInfo[0])"
      >

      <div
        v-if="item.typeInfo.temType == 3"
        class="scroll_list"
      >
        <div
          v-for="(n,m) in item.goodsInfo"
          :key="m"
          class="scroll_item"
          @click="goDetail(n)"
        >
          <img :src="n.image">
          <p class="desc">{{ n.goodsName }}</p>
          <p class="money">
            <em>¥</em>{{ n.vipPrice }}<span>
              <em>¥</em>{{ n.goodsPrice }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Scroll',
  props: {
    activityList: {
      type: null,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    goDetail(item) {
      // /goodsDetailZY?liveId='直播ID'&liveUserId='直播人ID'&parentTypeId='产品类型'&goodsId='产品ID'&skuid='产品规格ID'
      this.$router.push({
        path: '/goodsDetailZY',
        query: { liveId: '1', liveUserId: '32456', parentTypeId: '3', goodsId: item.goodId, skuid: item.skuId }
      })
    },
    gotoActhds(e) {
      this.$router.push({ path: '/acthds', query: { id: e.id }})
    }
  }
}
</script>
<style lang="less">
/*滑动商品*/
.fresh_scroll {
  width: 100%;
  margin: 0 auto 10px;

  img.tit {
    width: 100%;
    display: block;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }

  .scroll_list {
    width: 351px;
    //padding: 10px;
    // font-weight: bold;
    background: #fff;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    margin: 12px auto;

    .scroll_item {
      width: 93px;
      margin-right: 10px;

      img {
        border-radius: 9px;
        width: 93px;
        height: 93px;
        margin-bottom: 10px;
      }

      .desc {
        width: 93px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /*要显示的行数*/
        -webkit-box-orient: vertical;
        line-height: 19px;
        color: #333333;
      }

      .money {
        font-size: 17px;
        color: #c82519;
        padding-top: 20px;

        span {
          color: #bbbbbb;
          font-size: 12px;
          float: right;
          line-height: 23px;
        }

        em {
          font-size: 12px;
          font-style: normal;
        }
      }
    }
  }
}
</style>
