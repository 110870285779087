<template>
  <div>
    <van-swipe
      class="fresh-swipe wid"
      :autoplay="3000"
      indicator-color="white"
    >
      <van-swipe-item
        v-for="(item,index) in swiper"
        :key="index"
        @click="goAcitivy(item)"
      ><img v-lazy="item.url"></van-swipe-item>
    </van-swipe>

  </div>
</template>
<script>
import Vue from 'vue'
import { Lazyload, Swipe, SwipeItem } from 'vant'

Vue.use(Swipe).use(SwipeItem).use(Lazyload)
export default {
  name: 'Banner',
  components: {},
  props: {
    swiper: {
      type: null,
      default: null
    }
  },
  mounted() {
  },
  methods: {
    goAcitivy(data) {
      // this.$router.push({ path: '/acthds', query: {id:data.actId} }))
      if (data.redirectType === 3) {
        const obj = {
          product_type: 1,
          goodId: data.id,
          skuId: data.skuId
        }
        this.$store.dispatch('godetail', obj)
      } else if (data.redirectType === 29) {
        window.location.href = data.id
      } else if (data.redirectType === 39) {
        this.$router.push({ path: '/acthds', query: { id: data.id }})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wid {
  width: 351px;
  //width: 100%;
  margin: 8.5px auto 7.5px;
  border-radius: 9px;
  background: #fff;
  height: 136px;
}

.fresh-swipe {
  .van-swipe-item {
    color: #fff;
    text-align: center;
    // background-color: #39a9ed;
    img {
      width: 100%;
    }
  }
}
</style>
