<template>
  <div>
    <div class="gradList wid">
      <div
        v-for="(item,index) in categoryGrip"
        :key="index"
        class="gradItem"
        @click="goSearch(item)"
      >
        <img
          :src="item.productImage"
          alt="item.categoryThreeName"
        >
        <p v-html="item.categoryThreeName || item.categoryFirstName" />
      </div>
    </div>

  </div>
</template>
<script>
import { getClientInfo } from '@/utils/public.js'

export default {
  name: 'Grid',
  components: {},
  props: {
    'categoryGrip': {
      type: null,
      default: null
    },
    'hover': {
      type: null,
      default: null
    },
    'goodStore': {
      type: null,
      default: null
    },
    'env': {
      type: null,
      default: null
    }
  },
  data() {
    return {
      grid: ''
    }
  },
  mounted() {
  },
  methods: {
    goSearch(item) {
      const _this = this
      // == 'goodStore' 好衣库   否则 就是 生鲜、美妆、

      // eslint-disable-next-line eqeqeq
      if (_this.goodStore == 'goodStore') {
        if (getClientInfo()) {
          const _url =
            'https://web.yuetao.vip/clothes?epId=' +
            item.categoryFirstId +
            '&channelName=' +
            item.categoryFirstName +
            '&env=' +
            99

          const _Router = {
            redirectType: 111,
            redirectUrl: _url,
            name: item.categoryFirstName
          }
          this.$store.dispatch('RNrouter', _Router)
          // window.ReactNativeWebView.postMessage(JSON.stringify(_Router));
        } else {
          // 好衣库
          _this.$router.push({
            path: '/clothes',
            query: {
              epId: item.categoryFirstId,
              channelName: item.categoryFirstName,
              env: this.env
            }
          })
        }
      } else {
        // 生鲜、美妆、
        _this.$router.push({
          path: '/goodsList',
          query: { searchKeyword: item.categorySecondName, fromPage: _this.hover }
        })
      }
    }
  }

}
</script>

<style lang="less" scoped>
.wid {
  width: 351px;
  margin: 8.5px auto 7.5px;
  border-radius: 9px;
  background: #fff;
}

.iconList {
  padding: 14px 12px;
  border-radius: 9px;
  background: #fff;
}

.gradList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;

  .gradItem {
    width: 50px;
    margin: 4px 20px 0 0;

    img {
      width: 46px;
      height: 46px;
      margin-bottom: 9px;
      border-radius: 100%;
    }

    p {
      font-size: 12px;
      color: #333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 50px;
      text-align: center;
    }
  }

  .gradItem:nth-child(5n) {
    margin-right: 0;
  }
}
</style>
